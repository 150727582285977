import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import * as styles from "./bio.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faStackOverflow,
  faGithub,
  faLinkedinIn,
  faCodepen,
} from "@fortawesome/free-brands-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author
          social {
            twitter
            linkedin
            codepen
            github
            stackoverflow
            resume
          }
        }
      }
    }
  `);

  const { author, social } = data.site.siteMetadata;
  return (
    <>
      <h1 className={styles.heading}>Hi, I'm Aleks.</h1>
      <p className={styles.intro}>I build apps and ecommerce websites.</p>
      <div className={styles.bio}>
        <a
          href={`https://twitter.com/${social.twitter}`}
          title={`${author} on Twitter`}
          alt={`View ${author}'s profile on Twitter`}
          className={styles.twitter}
        >
          <FontAwesomeIcon
            className={styles.icon}
            icon={faTwitter}
            fixedWidth
          />
        </a>
        <a
          href={social.linkedin}
          title={`${author} on LinkedIn`}
          alt={`View ${author}'s profile on LinedIn`}
          className={styles.linkedin}
        >
          <FontAwesomeIcon
            className={styles.icon}
            icon={faLinkedinIn}
            fixedWidth
          />
        </a>
        <a
          href={social.github}
          title={`${author} on Github`}
          className={styles.github}
        >
          <FontAwesomeIcon className={styles.icon} icon={faGithub} fixedWidth />
        </a>
        <a
          href={social.codepen}
          title={`${author} on Codepen`}
          className={styles.codepen}
        >
          <FontAwesomeIcon
            className={styles.icon}
            icon={faCodepen}
            fixedWidth
          />
        </a>
        <a
          href={social.stackoverflow}
          title={`${author} on Stack Overflow`}
          className={styles.stackoverflow}
        >
          <FontAwesomeIcon
            className={styles.icon}
            icon={faStackOverflow}
            fixedWidth
          />
        </a>
        <a
          href={social.resume}
          title={`${author}'s Resume`}
          className={styles.resume}
        >
          <FontAwesomeIcon
            className={styles.icon}
            icon={faDownload}
            fixedWidth
          />
        </a>
      </div>
    </>
  );
};

export default Bio;
