// extracted by mini-css-extract-plugin
export var bio = "bio-module--bio--fDHiL";
export var codepen = "bio-module--codepen--VOVtL";
export var github = "bio-module--github--oCnNf";
export var heading = "bio-module--heading--KJXMd";
export var icon = "bio-module--icon--53Fl5";
export var intro = "bio-module--intro--Zmw6K";
export var linkedin = "bio-module--linkedin--gVhe8";
export var resume = "bio-module--resume--fEvvl";
export var stackoverflow = "bio-module--stackoverflow--jcw3V";
export var twitter = "bio-module--twitter--0VuRR";